import React, { Component } from 'react';
import styled from 'styled-components';
import Title from '../Components/Title';
import {MainLayout, InnerLayout} from '../styles/Layouts';

import axios from 'axios';
import { Link } from 'react-router-dom';

export default class BlogsPage extends Component{
    constructor(props){
        super(props);
        this.state = {blogs: []};
    }

    componentDidMount(){
        axios.get('https://server.atiqul-islam.com/blog/')
            .then(resp => {
                this.setState({blogs: resp.data})
            })
            .catch((error) => {
                console.log('error');
            });
    }

    render() {
        const BlogsStyled = styled.div`
            .blog{
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                grid-column-gap: 1rem;
                grid-row-gap: 1rem;
                @media screen and (max-width:1022px){
                    grid-template-columns: repeat(4, 1fr);
                }
                @media screen and (max-width:740px){
                    grid-template-columns: repeat(2, 1fr);
                }
                @media screen and (max-width:330px){
                    grid-template-columns: repeat(1, 1fr);
                }
                .blog-item{
                    background-color: var(--background-dark-color-2);
                    padding: 1rem 1rem;
                }
                .image{
                    width: 100%;
                    height: 80%;
                    overflow: hidden;
                    padding-bottom:.5rem;
                    img{
                        width: 100%;
                        height: 80%;
                        object-fit: cover;
                        transition: all .4s ease-in-out;
                        &:hover{
                            position: relative;
                            z-index: 2;
                            cursor: pointer;
                            transform: rotate(3deg) scale(1.1);
                        }
                    }
                }
                .title{
                    text-align: justify;
                    a{
                    font-size: .7rem;
                    padding: 2rem 0;
                    color: var(--white-color);
                    position: relative;
                    z-index: 2;
                    cursor: pointer;
                    transition: all .4s ease-in-out;
                    &:hover{
                        color: var(--primary-color);
                    }
                    }
                }
            }
        `;


        return (
        <MainLayout>
            <BlogsStyled>
                <Title title='Blog' span='Blog'/>
                <InnerLayout className={'blog'}>
                    {
                        this.state.blogs.map((blog, i)=>{
                            return <div key={i} className={'blog-item'}>
                                <div className="image">
                                    {/* <img src={require('../img/blogs/'+blog.image+'.svg').default} alt= {blog.title} /> */}
                                    <img src={blog.image} alt= {blog.title} />
                                </div>
                                <div className="title">
                                    <Link
                                    to={{
                                        pathname: `/blogs/${blog._id}`,
                                        state: {id: blog._id}}}
                                    >
                                        <h4>{i+1}. {blog.title}</h4>
                                    </Link>
                                </div>

                            </div>
                        }

                        )
                    }
                </InnerLayout>
            </BlogsStyled>
        </MainLayout>);
    }
}