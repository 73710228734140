import Sidebar from "./Components/Sidebar";
import HomePage from "./Pages/HomePage";
import AboutPage from "./Pages/AboutPage";
import BlogsPage from "./Pages/BlogsPage";
import BlogsPageDetails from "./Pages/BlogsPageDetails";
import ContactPage from "./Pages/ContactPage";
import PortfoliosPage from "./Pages/PortfoliosPage";
import ResumePage from "./Pages/ResumePage";
import PublicationsPage from "./Pages/PublicationsPage";
import { Route, Switch} from "react-router";
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Switchs from '@material-ui/core/Switch';
import {useEffect, useState} from 'react';
import styled from "styled-components";

function App() {
  const[theme, setTheme] = useState('light-theme');
  const [checked, setChecked] = useState(false);
  const [navToggle] = useState(false);

  useEffect(()=>{
    document.documentElement.className = theme;
  }, [theme])

  const themeToggler = () =>{
      if(theme === 'dark-theme'){
        setTheme('light-theme');
        setChecked(false)
      }else{
        setTheme('dark-theme');
        setChecked(true)
      }
  }

 return(
   <div className="App">
     <Sidebar navToggle={navToggle} />
    <MainStyled>
      
      <div className="theme">
        <div className="light-dark-mode">
          <div className="left-content"><Brightness4Icon /></div>
          <div className="right-content">
            <Switchs
              value=""
              checked={checked}
              inputProps={{ 'aria-label': '' }}
              size="small"
              onClick={themeToggler}
            /> 
          </div>
        </div>
      </div>

          <Switch>
            <Route path="/" exact> <HomePage /> </Route>
            <Route path="/about" exact> <AboutPage /> </Route>
            <Route path="/blogs" exact> <BlogsPage /> </Route>
            <Route path="/blogs/:id" exact> <BlogsPageDetails /> </Route>
            <Route path="/contact" exact> <ContactPage /> </Route>
            <Route path="/portfolios" exact> <PortfoliosPage /> </Route>
            <Route path="/resume" exact> <ResumePage /> </Route>
            <Route path="/publications" exact> <PublicationsPage /> </Route>
          </Switch>
      </MainStyled>        
  </div>
   
 );
}

const MainStyled = styled.div`
margin-left: 3rem;
`;

export default App;
