import React, { Component, useEffect, useState } from 'react';
import styled from 'styled-components';
import Title from '../Components/Title';
import {MainLayout, InnerLayout} from '../styles/Layouts';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';

function BlogsPageDetails (){
    const [blog, setBlog] = useState([]);
    const params = useParams();
    function getPagamsValue(){
        console.log(params.id);
        axios.get('https://server.atiqul-islam.com/blog/'+params.id)
            .then(resp => {setBlog(resp.data)})
            .catch((error) => {
                console.log('error');
            });
    }
    
    useEffect(()=>{
        getPagamsValue();
    },[])
    

    return(
        <MainLayout>
            <BlogsStyled>
                <Title title='Blog' span='Blog'/>
                <InnerLayout className={'blog'}>
                    <div className="modal">
                        {blog.discription}
                    </div>
                </InnerLayout>
            </BlogsStyled>
        </MainLayout>
    );
}

const BlogsStyled = styled.div`
        .blog{
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-column-gap: 1rem;
            grid-row-gap: 1rem;
            @media screen and (max-width:1022px){
                grid-template-columns: repeat(4, 1fr);
            }
            @media screen and (max-width:740px){
                grid-template-columns: repeat(2, 1fr);
            }
            @media screen and (max-width:330px){
                grid-template-columns: repeat(1, 1fr);
            }
            .blog-item{
                background-color: var(--background-dark-color-2);
                padding: 1rem 1rem;
            }
            .image{
                width: 100%;
                height: 80%;
                overflow: hidden;
                padding-bottom:.5rem;
                img{
                    width: 100%;
                    height: 80%;
                    object-fit: cover;
                    transition: all .4s ease-in-out;
                    &:hover{
                        position: relative;
                        z-index: 2;
                        cursor: pointer;
                        transform: rotate(3deg) scale(1.1);
                    }
                }
            }
            .title{
                text-align: justify;
                a{
                   font-size: .7rem;
                   padding: 2rem 0;
                   color: var(--white-color);
                   position: relative;
                   z-index: 2;
                   cursor: pointer;
                   transition: all .4s ease-in-out;
                   &:hover{
                       color: var(--primary-color);
                   }
                }
            }
        }
    `;

export default  BlogsPageDetails;