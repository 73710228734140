import React, {useState, useEffect} from 'react';
import {MainLayout, InnerLayout} from '../styles/Layouts';
import Title from '../Components/Title';
import Menu from '../Components/Menu';
import Button from '../Components/Button';
import styled from 'styled-components';
import axios from 'axios';



function PortfoliosPage(){
    const [menuItem, setManuItems] = useState([]);
    const [button, setButton] = useState([]);
    

    const getValues = (select) =>{
        axios.get("https://server.atiqul-islam.com/portfolio/")
            .then(response => response.data)
            .then((data) => {
                const allButton = ['All', ...new Set(data.map(item => item.category))]
                setButton(allButton);
                if(select === 'All'){
                    setManuItems(data);
                    return;
                }
                
                const filteredData = data.filter(item => item.category === select);
                setManuItems(filteredData);
            });
        ;  
    };

    useEffect(()=>{
        getValues("All");
    },[])
    
    const filter = (button) => {
        getValues(button);
    }
    return(
        <MainLayout>
            <PortfolioPageStyled>
                <Title title='Portfolio' span='Portfolio'/>
                <InnerLayout>
                    <Button filter={filter} button={button} />
                    <Menu menuItem={menuItem} />
                </InnerLayout>
            </PortfolioPageStyled>
        </MainLayout>
    );
}

const PortfolioPageStyled = styled.div`
    @media screen and (max-width:350px){
           margin-top: 5rem;
    }
`;

export default PortfoliosPage;