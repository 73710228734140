import React from 'react';
import styled from 'styled-components';
import {InnerLayout} from '../styles/Layouts';
import Title from '../Components/Title';
import ServiceCard from '../Components/ServiceCard';
import web from '../img/service/web.svg';
import api from '../img/service/api.svg';
import apps from '../img/service/apps.svg';

function ServicesSection(){
    return(
        <InnerLayout>
            <ServicesSectionStyled>
                <Title title={'Services'} span={'Services'} />
                <div className="services">
                    <ServiceCard 
                        image={web}
                        title={'Web Development'}
                        paragraph={'I produce Professional Website which is search engines and users friendly, that does not cost a fortune in hosting, and it is easy to update and maintain. I can also integrate a good Content Management System (CMS), which will let you update your website yourself, even without any programming knowledge.'}
                    />
                    <ServiceCard 
                        image={apps}
                        title={'Apps Development'}
                        paragraph={'I am sure you are aware that having a professionally designed website can increase your sales and bring your company exposure to thousands of potential clients. So you have to ask yourself, "Can I afford to NOT have professional mobile Apps?"'}
                    />
                    {/*<ServiceCard 
                        image={api}
                        title={'Api Development'}
                        paragraph={'Im sure youre aware that having a professionally designed website can increase your sales and bring your company exposure to thousands of potential clients. So you have to ask yourself, "Can I really afford to NOT have a professional website?"'}
                    />*/}
                </div>
            </ServicesSectionStyled>
        </InnerLayout>
    );
}

const ServicesSectionStyled = styled.section`
    .services{
        margin-top: 5rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5rem;
        @media screen and (max-width:1000px){
            flex-direction: column;
        }
        @media screen and (max-width:950px){
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (max-width:650px){
            grid-template-columns: repeat(1, 1fr);
        }
    }
`;

export default ServicesSection;