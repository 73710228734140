import React from 'react'
import styled from 'styled-components';
import {InnerLayout} from '../styles/Layouts';
import Title from '../Components/Title';
import ProgressBar from '../Components/ProgressBar';

function Skills(){
    return(
        <SkillsStyled>
            <Title title='My Skills' span='My Skills'/>
            <InnerLayout>
                <div className="skills">
                    <ProgressBar 
                        title={'HTML'}
                        width={'70%'}
                        text={'70%'}
                    />
                    <ProgressBar 
                        title={'CSS'}
                        width={'85%'}
                        text={'85%'}
                    />
                    <ProgressBar 
                        title={'JAVASCRIPT'}
                        width={'80%'}
                        text={'80%'}
                    />
                    <ProgressBar 
                        title={'My SQL'}
                        width={'75%'}
                        text={'75%'}
                    />
                    <ProgressBar 
                        title={'REACT JS'}
                        width={'75%'}
                        text={'75%'}
                    />
                    <ProgressBar 
                        title={'PHP'}
                        width={'70%'}
                        text={'70%'}
                    />
                    <ProgressBar 
                        title={'PYTHON'}
                        width={'60%'}
                        text={'60%'}
                    />
                </div>
            </InnerLayout>
        </SkillsStyled>
    );
}

const SkillsStyled = styled.section`
    margin-top: 5rem;
    margin-bottom: 3rem;
    .skills{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 2rem;
        grid-column-gap: 2rem;
        @media screen and (max-width:700px){
            margin-top: 5rem;
            grid-template-columns: repeat(1, 1fr);
        }
    }
`;

export default Skills;