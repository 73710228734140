import React from 'react';
import styled from 'styled-components';
// import resume from '../img/resume.jpg';
import resume from '../img/Picture_037.jpg';
import PrimaryButton from '../Components/PrimaryButton';

function ImageSection(){
    return(
        <ImageSectionStyled>
            <div className="left-content">
                <img src={resume} alt="" />
            </div>
            <div className="right-content">
                <div className="sub-title">
                    <h4>I am <span>Md Atiqul Islam</span></h4>
                </div>
                <p className="paragraph">
                To enhance my working capability, and professional skills and to excel in my field through enough hard work and research in the best possible way with absolute determination and commitment.
                </p>
                <div className="about-info">
                    <div className="info-title">
                        <p>Full Name</p>
                        <p>Age</p>
                        <p>Nationality</p>
                        <p>Languages</p>
                        <p>Location</p>
                        <p>Service</p>
                    </div>
                    <div className="info">
                        <p>: Md Atiqul Islam</p>
                        <p>: 28</p>
                        <p>: Bangladeshi</p>
                        <p>: Bangla, English</p>
                        <p>: Dhaka, Bangladesh</p>
                        <p>: Chief Operating Officer (Emergence Lab) </p>
                    </div>
                </div>
                <PrimaryButton title='Download Cv' target="_blank"/>
            </div>
        </ImageSectionStyled>
    );
}

const ImageSectionStyled = styled.div`
    margin-top: 3rem;
    display: flex;
    @media screen and (max-width:1000px){
        flex-direction: column;
        .left-content{
            margin-bottom: 2rem;
        }
    } 
    .left-content{
        width: 100%;
        img{
            width: 90%;
            object-fit: cover;
        }
    }
    .right-content{
        h4{
            font-size: 1.5rem;
            color: ver(--white-color);
            span{
                font-size: 1.8rem;
                color: var(--primary-color);
            }
        }
        .paragraph{
            padding: 1rem 0;
            text-align: justify;
            font-size: 1rem;
        }
        .about-info{
            display: flex;
            padding-bottom: 1.4rem;
            .info-title{
                padding-right: 3rem;
                p{
                    font-weight: 600;
                }
            }
            .info-title, .info{
                p{
                    padding: .3rem 0;
                    @media screen and (max-width:650px){
                        font-size: .5rem;
                    }
                }
            }
        }
    }
`;

export default ImageSection;