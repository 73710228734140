import React from 'react';
import styled from 'styled-components';
import {InnerLayout} from '../styles/Layouts';
import Title from '../Components/Title';
import SmallTitle from '../Components/SmallTitle';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import SchoolIcon from '@material-ui/icons/School';
import ResumeItem from '../Components/ResumeItem';

function Resume(){
    const briefcase = <BusinessCenterIcon />
    const school = <SchoolIcon />
    return(
        <ResumeStyled>
            <Title title='Resume' span='Resume'/>
            <InnerLayout>
                <div className="small-title">
                    <SmallTitle icon={briefcase} title={'Working Experince'} />
                </div>
                <div className="resume-content">
                    <ResumeItem 
                        year={'August 2022-Present'}
                        title={'Chief Operating Officer & Co-Funder'}
                        subTitle={'Emergence Lab'}
                        text={''}
                    />
                    <ResumeItem 
                        year={'December 2021-August 2022'}
                        title={'Jr Software Developer'}
                        subTitle={'Disseminare Consulting Private Limited'}
                        text={''}
                    />
                    <ResumeItem 
                        year={'March 2020- August 2020'}
                        title={'Web Application Developer'}
                        subTitle={'Onusative limited'}
                        text={''}
                    />
                    <ResumeItem 
                        year={'April 2019- March 2020'}
                        title={'Web Developer'}
                        subTitle={'Best Hunt Deal'}
                        text={'Develop an e-commerce website using WordPress.'}
                    />
                    <ResumeItem 
                        year={'May 2018-December 2018'}
                        title={'Research Assistant (Honorary)'}
                        subTitle={'North South University'}
                        text={'I work under Ms. Tamanna Mothar as a Research Assistant'}
                    />
                </div>
                <div className="small-title u-small-title-margin">
                    <SmallTitle icon={school} title={'Educational Qualifications'} />
                </div>
                <div className="resume-content">
                    <ResumeItem 
                        year={'2014-2019'}
                        title={'BSc Computer Science and Engineering'}
                        subTitle={'North South University'}
                        text={'Department of Electrical and Computer Engineering'}
                    />
                    <ResumeItem 
                        year={'2012-2013'}
                        title={'Higher Secondary Certificate (HSC)'}
                        subTitle={'Chuadanga Govt Collage, Chuadanga'}
                        text={'Science Group'}
                    />
                     <ResumeItem 
                        year={'2002-2010'}
                        title={'Secondary School Certificate (SSC)'}
                        subTitle={'VJ Govt High School, Chuadanga'}
                        text={'Science Group'}
                    />
                </div>
            </InnerLayout>
        </ResumeStyled>
    );
}

const ResumeStyled = styled.section`
    .small-title{
        margin-top: 3rem;
        padding-bottom: 3rem;
    }
    .u-small-title-margin{
        margin-top: 5rem;
    }
    .resume-content{
        border-left: 2px solid var(--border-color);
        //text-align: justify;
    }
`;

export default Resume;