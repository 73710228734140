import React from 'react';
import Particle from '../Components/Particle';
import styled from 'styled-components';
import FacebookIcon from '@material-ui/icons/Facebook'; 
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import GithubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';


function HomePage() {
    return(
        <HomePageStyled>
            <div className="particle-con">
                <Particle />
            </div>
            <div className="typography">
                <h1>Hi, I'm <span>Md Atiqul Islam</span></h1>
                <p>I am a web developer belonging to Chuadanga, Bangladesh. I started my career as a junior web designer, I am working as a web developer for the last 2 years.I currently hold the position of <span style={{color: "var(--primary-color)"}}>Chief Operating Officer</span> of Emergence Lab. I want to deliver my best within the web designing and development area where performance is measured through achievements because the web is my way and programming is my passion. Do you intend to launch a website for personal or professional use? I am ready to assist you.</p>
                <div className="icons">
                    <a href="http://www.facebook.com/mairahid2" className="icon i-facebook" target="_blank"><FacebookIcon /></a>
                    <a href="https://twitter.com/AtiqulI83780029" className="icon i-twitter" target="_blank"><TwitterIcon/></a>
                    <a href="https://www.linkedin.com/in/md-atiqul-islam/" className="icon i-linkedin" target="_blank"><LinkedInIcon/></a>
                    <a href="https://instagram.com/atiqulrahid" className="icon i-instagram" target="_blank"><InstagramIcon/></a>
                    <a href="https://github.com/atiqrahid" className="icon i-github" target="_blank"><GithubIcon /></a>
                </div>

            </div> 
        </HomePageStyled>
    )
}

const HomePageStyled = styled.header`
    width: 100%;
    height: 100%;
    position: relative;
    .p-particles-js{
        position: absolute;
        top: 0;
        left: 0;
    }
    .typography{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 90%;

        .icons{
            display: flex;
            justify-content: center;
            margin-top: 1rem;
            .icon{
                border: 2px solid var(--border-color);
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                transition: all .4s ease-in-out;
                cursor: pointer;
                &:hover{
                    border: 2px solid var(--primary-color);
                    color: var(--primary-color);
                }
                &:not(:last-child){
                    margin-right: 1rem;
                    @media screen and (max-width:421px){
                        margin-right: .5rem; 
                    }
                }
                svg{
                    margin: .5rem;
                    @media screen and (max-width:421px){
                        margin: .2rem; 
                    }
                }
            }
            .i-instagram{
                &:hover{
                    border: 2px solid red;
                    color: red;
                }
            }
            .i-twitter{
                &:hover{
                    border: 2px solid #1DA1F2;
                    color: #1DA1F2;
                }
            }
            .i-github{
                &:hover{
                    border: 2px solid #4078c0;
                    color: #4078c0;
                }
            }
            .i-linkedin{
                &:hover{
                    border: 2px solid #0e76a8;
                    color: #0e76a8;
                }
            }
        }
    }
`;

export default HomePage;