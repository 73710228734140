import styled from 'styled-components';
import React from 'react';
import {MainLayout} from '../styles/Layouts';
import ImageSection from '../Components/ImageSection';
import Title from '../Components/Title';
//import ReviewsSetion from '../Components/ReviewsSetion';
import ServicesSection from '../Components/ServicesSection';
import Resume from '../Components/Resume';
import Skills from '../Components/Skills';

function AboutPage(){
    return(
        <MainLayout>
            <AboutStyled>
            <Title title='About Me' span='About Me'/>
            <ImageSection />
            <Skills />
            <Resume />
            <ServicesSection />
            {//<ReviewsSetion />   
            }
            </AboutStyled>
        </MainLayout>
    );
}

const AboutStyled = styled.div`
   
`;

export default AboutPage;