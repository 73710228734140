import React, { Component } from 'react';
import styled from 'styled-components';
import Title from '../Components/Title';
import {MainLayout, InnerLayout} from '../styles/Layouts';

import Publication from '../Components/Publication';

import axios from 'axios';
import { Link } from 'react-router-dom';

export default class PublicationsPage extends Component{
    constructor(props){
        super(props);
        this.state = {publications: []};
    }

    componentDidMount(){
        axios.get('https://server.atiqul-islam.com/publication/')
            .then(resp => {
                this.setState({publications: resp.data})
            })
            .catch((error) => {
                console.log('error');
            });
    }

    render() {
        const PublicationPageStyled = styled.div`
            @media screen and (max-width:450px){
                margin-top: 5rem;
            }
        `;
    return(
        <MainLayout>
            <PublicationPageStyled>
                <Title title='Publication' span='Publication'/>
                <InnerLayout className={"publication"}>
                
                {
                    this.state.publications.map((publication, i)=>{
                        return <Publication values={publication} key={i} show={i}></Publication>
                    }
                    )
                }
                </InnerLayout>
            </PublicationPageStyled>
        </MainLayout>);
    }
}