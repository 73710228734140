import React from 'react';
import styled from 'styled-components';
import Title from '../Components/Title';
import{MainLayout, InnerLayout} from '../styles/Layouts';
import PrimaryButton from '../Components/PrimaryButton';
import ContactItem from '../Components/ContactItem';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import emailjs from 'emailjs-com';
import {useState} from 'react';

const Result = () => {
    return (
        <p>Your Message has been Successfully sent. I will contact you soon</p>
    );
};

function ContactPage(){
    const [result, showResult] = useState(false);
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_wp3j5wg', 'template_03az0vd', e.target, 'user_soQ5mxnJUzfwmkFktdvdk')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset();
          showResult(true);
      }

    const phone = <PhoneIcon />
    const email = <EmailIcon />
    const location = <LocationOnIcon />
    return(
        <MainLayout>
            <Title title='Contact' span='Contact'/>
            <ContactPageStyled>
                <InnerLayout className={'contact-section'}>
                    <div className="left-content">
                        <div className="contact-title">
                            <h4>Get In Touch</h4>
                        </div>
                        <form className="form" onSubmit={sendEmail}>
                            <div className="form-field">
                                <label htmlFor="name">Enter your name:</label>
                                <input type="text" id="name" name="name"/>
                            </div>
                            <div className="form-field">
                                <label htmlFor="email">Enter your email:</label>
                                <input type="text" id="email" name="email"/>
                            </div>
                            <div className="form-field">
                                <label htmlFor="subject">Enter your subject:</label>
                                <input type="text" id="subject" name="subject"/>
                            </div>
                            <div className="form-field">
                                <label htmlFor="text-area">Enter your Message:</label>
                                <textarea  id="textarea" cols="30" rows="10" name="message"></textarea>
                            </div>
                            <div className="form-field f-button">
                               <button value="Send Message"> <PrimaryButton title={'Send Email'} /></button>
                            </div>
                            <div className="row">
                                {result ? <Result /> : null}
                            </div>
                        </form>
                    </div>
                    <div className="right-content">
                        <ContactItem title={'Phone'} icon={phone} con1={'+8801841069049'} />
                        <ContactItem title={'Email'} icon={email} con1={'contact@atiqul-islam.com'} />
                        <ContactItem title={'Location'} icon={location} con1={'Dhaka'} con2={'Bangladesh'} />
                    </div>
                </InnerLayout>
            </ContactPageStyled>
        </MainLayout>
    );
}

const ContactPageStyled = styled.section`
    .contact-section{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 3rem;
        @media screen and (max-width:978px){
            grid-template-columns: repeat(1, 1fr);
            .f-button{
                margin-bottom: 3.5rem;
            }
        }
        .right-content{
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            @media screen and (max-width:502px){
                width: 80%;
            }
        }
        .contact-title{
            h4{
                color: var(--white-color);
                padding: 1rem 0;
                font-size: 1.8rem;
            }
        }
        .form{
            width: 100%;
            @media screen and (max-width:502px){
                width: 100%;
            }
            .form-field{
                margin-top: .2rem;
                position: relative;
                width: 100%;
                lable{
                    position: absolute;
                    left: 20px;
                    top: -19px;
                    display: inline-block;
                    background-color: var(--background-dark-color);
                    padding: .4rem .5rem;
                    color: inherit;
                }
                input{
                    border: 1px solid var(--border-color);
                    outline: none;
                    background: transparent;
                    height: 50%;
                    padding: 0 10px;
                    width: 100%;
                    color: inherit;
                    @media screen and (max-width:330px){
                        width: 60%;
                        }
                }
                textarea{
                    background-color: transparent;
                    border: 1px solid var(--border-color);
                    outline: none;
                    color: inherit;
                    width: 100%;
                    padding: .8rem 1rem;
                    @media screen and (max-width:330px){
                        width: 60%;
                    }
                }
            }
        }
    }
`;

export default ContactPage;