import React from 'react';
import styled from 'styled-components';

function ContactItem({icon, title, con1,con2}) {
    return (
        <ContactItemStyled>
            <div className="left-content">
                <p>{icon}</p>
            </div>
            <div className="right-content">
                <h6>{title}</h6>
                <p>{con1}</p>
                <p>{con2}</p>
            </div>
        </ContactItemStyled>
    )
}

const ContactItemStyled = styled.div`
    padding: 1rem 1rem;
    background-color: var(--background-dark-color-2);
    display: flex;
    align-items: center;
    @media screen and (max-width:330px){
        width: 90%;
        }
    &:not(:last-child){
        margin-bottom: 1.5rem;
    }
    .left-content{
        padding: 1.5rem;
        border: 1px solid var(--border-color);
        font-size: .5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1.5rem;
        svg{
            font-size: 3rem;
        }
    }
    .right-content{
        h6{
            color: var(--white-color);
            font-size: 1.2rem;
            padding-bottom: .6rem;
        }
        p{
            padding: .1rem 0;
            @media screen and (max-width:340px){
                font-size: .5rem;
                }
        }
    }
`;

export default ContactItem;