import React from 'react';
import styled from 'styled-components';
import SecondaryButton from '../Components/SecondaryButton';
import moment from 'moment/moment';
function Publication(p){
    return(
        <PublicationStyled>
            <div className="left-content">
                <img src={require("../img/publication/"+p.values.image).default} alt="" />
            </div>
            <div className="right-content">
                <div className="sub-title">
                    <h4>{(p.show)+1}. <span>{p.values.title}</span></h4>
                </div>
                <p className="paragraph">{p.values.discribe}</p>
                <div className="about-info">
                    <div className="info-title">
                        <p>Date</p>
                        <p>Doi</p>
                        <p>Conference</p>
                        <p>link</p>
                    </div>
                    <div className="info">
                        <p>: {moment(p.values.date).format('YYYY-MM-DD')}</p>
                        <p>: {p.values.doi}</p>
                        <p>: {p.values.conference}</p>
                        <p>: <a  href={p.values.link} target="_blank"><SecondaryButton title={'View'} /></a></p>
                    </div>
                </div>
            </div>
        </PublicationStyled>
    );
}

const PublicationStyled = styled.div`
    margin-top: 5rem;
    display: flex;
    @media screen and (max-width:1000px){
        flex-direction: column;
        .left-content{
            margin-bottom: 2rem;
        }
    } 
    .left-content{
        width: 65%;
        img{
            width: 90%;
            object-fit: cover;
        }
    }
    .right-content{
        width: 80%;
        .sub-title{
            text-align: justify;
        }
        h4{
            font-size: 1.5rem;
            color: ver(--white-color);
            span{
                font-size: 1.5rem;
                color: var(--primary-color);
            }
        }
        .paragraph{
            padding: 1rem 0;
            text-align: justify;
        }
        .about-info{
            width: 120%;
            display: flex;
            padding-bottom: 1.4rem;
            .info-title{
                padding-right: .5rem;
                p{
                    font-weight: 600;
                }
            }
            .info-title, .info{
                p{
                    padding: .3rem 0;
                    @media screen and (max-width:650px){
                        font-size: .7rem;
                    }
                    @media screen and (max-width:330px){
                        font-size: .3rem;
                    }
                    @media screen and (max-width:230px){
                        font-size: .1rem;
                    }
                }
            }
        }
    }
`;

export default Publication;