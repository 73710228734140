import React, { useState } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import home from '../img/sideber/home.svg';
import about from '../img/sideber/about.svg';
//import resume from '../img/sideber/resume.svg';
import publication from '../img/sideber/publication.svg';
import portfolio from '../img/sideber/portfolio.svg';
import blog from '../img/sideber/blog.svg';
import contact from '../img/sideber/contact.svg';
import avater from '../img/sideber/avatar.jpg';

const Sidebar = () => {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
  
    const [profileClick, setprofileClick] = useState(false);
    const handleProfileClick = () => setprofileClick(!profileClick);
  
    return (
      <Container>
        <Button clicked={click} onClick={() => handleClick()}></Button>
        <SidebarContainer>
          <SlickBar clicked={click}>
            <Item
              onClick={() => setClick(false)}
              exact
              activeClassName="active"
              to="/"
            >
              <img src={home} alt="" />
              <Text clicked={click}>Home</Text>
            </Item>

            <Item
              onClick={() => setClick(false)}
              activeClassName="active"
              to="/about"
            >
              <img src={about} alt="" />
              <Text clicked={click}>About</Text>
            </Item>
            {/*
            <Item
              onClick={() => setClick(false)}
              activeClassName="active"
              to="/resume"
            >
              <img src={resume} alt="" />
              <Text clicked={click}>Resume</Text>
            </Item> */}

            <Item
              onClick={() => setClick(false)}
              activeClassName="active"
              to="/publications"
            >
              <img src={publication} alt="" />
              <Text clicked={click}>Publication</Text>
            </Item>

            <Item
              onClick={() => setClick(false)}
              activeClassName="active"
              to="/portfolios"
            >
              <img src={portfolio} alt="" />
              <Text clicked={click}>Portfolio</Text>
            </Item>

            <Item
              onClick={() => setClick(false)}
              activeClassName="active"
              to="/blogs"
            >
              <img src={blog} alt="" />
              <Text clicked={click}>Blog</Text>
            </Item>

            <Item
              onClick={() => setClick(false)}
              activeClassName="active"
              to="/contact"
            >
              <img src={contact} alt="" />
              <Text clicked={click}>Contact</Text>
            </Item>
          </SlickBar>
  
          <Profile clicked={profileClick}>
            <img
              onClick={() => handleProfileClick()}
              src={avater}
              alt=""
            />
            <Details clicked={profileClick}>
              <Name>
                <a href="https://atiqul-islam.com/about" target="_blank" ><h4>Md Atiqul Islam</h4></a>
              </Name>
            </Details>
          </Profile>
        </SidebarContainer>
      </Container>
    );
  };
  
 
const Container = styled.div`
  position: fixed;
  z-index: 2;

  .active {
    border-right: 2px solid var(--white);

    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
        brightness(103%) contrast(103%);
    }
  }
`;

const Button = styled.button`
  background-color: var(--black);
  border: none;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin: 0.2rem 0 0 0.2rem;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  &::before,
  &::after {
    content: "";
    background-color: var(--white);
    height: 1px;
    width: 1rem;
    position: absolute;
    transition: all 0.3s ease;
  }

  &::before {
    top: ${(props) => (props.clicked ? "1." : ".75rem")};
    transform: ${(props) => (props.clicked ? "rotate(135deg)" : "rotate(0)")};
  }

  &::after {
    top: ${(props) => (props.clicked ? "1" : "1.2rem")};
    transform: ${(props) => (props.clicked ? "rotate(-135deg)" : "rotate(0)")};
  }
`;

const SidebarContainer = styled.div`
  background-color: var(--black);
  width: 2.5rem;
  height: 80vh;
  margin-top: .5rem;
  border-radius: 0 25px 25px 0;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const SlickBar = styled.ul`
  color: var(--white);
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--black);
  padding: .5rem 0;
  position: absolute;
  top: 6rem;
  left: 0;
  width: ${(props) => (props.clicked ? "9rem" : "2.5rem")};
  transition: all 0.5s ease;
  border-radius: 0 25px 25px 0;
`;

const Item = styled(NavLink)`
  text-decoration: none;
  color: var(--white);
  width: 100%;
  padding: .5rem 0;
  cursor: pointer;
  display: flex;
  padding-left: .5rem;

  &:hover {
    border-right: 2px solid var(--primary-color);

    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
        brightness(103%) contrast(103%);
    }
  }

  img {
    width: 1rem;
    height: auto;
    filter: invert(92%) sepia(4%) saturate(1033%) hue-rotate(169deg)
      brightness(78%) contrast(85%);
  }
`;

const Text = styled.span`
  width: ${(props) => (props.clicked ? "100%" : "0")};
  overflow: hidden;
  font-size: 1rem;
  margin-left: ${(props) => (props.clicked ? ".5rem" : "0")};
  transition: all 0.3s ease;
`;

const Profile = styled.div`
  width: ${(props) => (props.clicked ? "12rem" : "2rem")};
  height: 4rem;
  font-size: 2rem;
  padding: .5rem .2rem;
  //border: 2px solid var(--white);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${(props) => (props.clicked ? "8.5rem" : "0")};
  background-color: var(--black);
  color: var(--white);
  transition: all 0.3s ease;

  img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      border: .5px solid var(--grey);
      padding: .5px;
    }
  }
`;

const Details = styled.div`
  display: ${(props) => (props.clicked ? "flex" : "none")};
  justify-content: space-between;
  align-items: center;
`;

const Name = styled.div`
  padding: 0.5rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h4 {
    display: inline-block;
    font-size: .9rem;
    color: var(--pro-color);
  }

  a {
    font-size: 0.2rem;
    text-decoration: none;
    color: var(--grey);

    &:hover {
      text-decoration: underline;
    }
  }
`;

  export default Sidebar;